import { db } from "../../../services/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { getLoginUserId } from "../Auth/AuthStates";
import { CONSTANT } from "../../../constant";

export const getAllQuery = async (dbCollection: any) => {
  try {
    let merchant_ID = localStorage.getItem(CONSTANT.MERCHANT_DATA);
    let data: any = [];
    const q = query(
      collection(db, dbCollection),
      where("merchant_id", "==", merchant_ID)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      if (doc.data().isArchive === false)
      data.push(doc.data());
    });
    return data;
  } catch (error: any) {
    console.warn(error.message);
    return { error: error.message };
  }
};

export const getAllQueryByMerchant = async (dbCollection: any) => {
  try {
    let merchant_ID = localStorage.getItem(CONSTANT.MERCHANT_DATA);
    let data: any = [];
    const q = query(
      collection(db, dbCollection),
      where("merchant_id", "==", merchant_ID)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      if (doc.data().isArchive === false)
      data.push(doc.data());
    });
    return data;
  } catch (error: any) {
    console.warn(error.message);
    return { error: error.message };
  }
};

export const getAllQueryWithArchive = async (dbCollection: any) => {
  try {
    let merchant_ID = localStorage.getItem(CONSTANT.MERCHANT_DATA);
    let data: any = [];
    const q = query(
      collection(db, dbCollection),
      where("merchant_id", "==", merchant_ID)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });
    return data;
  } catch (error: any) {
    console.warn(error.message);
    return { error: error.message };
  }
};
