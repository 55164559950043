import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  getLoginUserId,
  getSession,
  getSessionData,
} from "../../utilities/Auth/AuthStates";
import Header from "../../Components/Header";
import {
  Box,
  Button,
  FormHelperText,
  InputLabel,
  TextField,
} from "@mui/material";
import Loader from "../../Components/Loader";
import { getAllByAttributesQuery } from "../../utilities/queries/AddQueries";
import { CONSTANT, DB_COLLECTION } from "../../../constant";
import { updateQuery } from "../../utilities/queries/updateQuery";

type Props = {};

const Profile = (props: Props) => {
  const [firstName, setfirstName] = useState<string>("");
  const [lastName, setlastName] = useState<string>("");
  const [merchantName, setmerchantName] = useState<string>("");

  const [phoneNumber, setphoneNumber] = useState<string>("");
  const [email, setemail] = useState<string>("");
  const [faceebookPageLink, setfaceebookPageLink] = useState<string>("");

  const [instagramID, setinstagramID] = useState<string>("");
  const [lineNumberID, setlineNumberID] = useState<string>("");
  const [websiteLink, setwebsiteLink] = useState<string>("");
  const [merchant_ID, setmerchant_ID] = useState<string>("");

  const [disabled, setdisabled] = useState<boolean>(true);
  const [isLoading, setisLoading] = useState<boolean>(false);

  const handleChange: any = (setState: any) => (event: any) => {
    setdisabled(false)
    setState(event.target.value);
  };

  const UpdateProfile = async () => {
    setisLoading(true)
    let userUpdatedData: any = {
      firstName,
      lastName,
      merchantName,
      phoneNumber,
      faceebookPageLink,
      instagramID,
      lineNumberID,
      websiteLink
    }
    await updateQuery(DB_COLLECTION.DP_MERCHANT_USERS, merchant_ID, userUpdatedData);
    setisLoading(false)
    alert("Profile Updated.")
  };

  const GetProfileData = async () => {
    let merchant_ID = localStorage.getItem(CONSTANT.MERCHANT_DATA);
    if (
      merchant_ID !== null &&
      merchant_ID !== undefined &&
      merchant_ID !== "null"
    ) {
      const { response, data }: any = await getAllByAttributesQuery(
        DB_COLLECTION.DP_MERCHANT_USERS,
        "id",
        merchant_ID,
        CONSTANT.equal_operator
      );

      if (response && data && data?.length > 0) {
        setfirstName(data[0].firstName);
        setlastName(data[0].lastName);
        setmerchantName(data[0].merchantName);
        setphoneNumber(data[0].phoneNumber);
        setemail(data[0].email);
        setfaceebookPageLink(data[0].faceebookPageLink);
        setinstagramID(data[0].instagramID);
        setlineNumberID(data[0].lineNumberID);
        setwebsiteLink(data[0].websiteLink);
        setmerchant_ID(data[0].id)
      } else {
        console.error("User Data not exist.");
      }
    }
  };

  useEffect(() => {
    getSession();
    GetProfileData();
  }, []);

  // useMemo(() => {
  //   // let session: any = getLoginUserId();
  //   // session && GetProfileData(session);
  //   let session: any = getLoginUserId()
  //   console.log("session", session)
  // }, []);

  return (
    <Fragment>
      <Header>
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <Box style={{ fontSize: "2em" }}>Profile</Box>

          <Box
            style={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: "auto auto auto auto",
              columnGap: "35px",
              rowGap: "30px",
            }}
          >
            
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              value={email}
              // onChange={handleChange(setemail)}
              disabled={true}
            />
            <TextField
              id="outlined-basic"
              label="First Name"
              variant="outlined"
              value={firstName}
              onChange={handleChange(setfirstName)}
            />
            <TextField
              id="outlined-basic"
              label="Last Name"
              variant="outlined"
              value={lastName}
              onChange={handleChange(setlastName)}
            />
            <TextField
              id="outlined-basic"
              label="Phone Number"
              variant="outlined"
              value={phoneNumber}
              onChange={handleChange(setphoneNumber)}
            />
            <TextField
              id="outlined-basic"
              label="Merchant Name"
              variant="outlined"
              value={merchantName}
              onChange={handleChange(setmerchantName)}
            />
            <TextField
              id="outlined-basic"
              label="Faceebook Page Link"
              variant="outlined"
              value={faceebookPageLink}
              onChange={handleChange(setfaceebookPageLink)}
            />
            <TextField
              id="outlined-basic"
              label="Instagram ID"
              variant="outlined"
              value={instagramID}
              onChange={handleChange(setinstagramID)}
            />

            <TextField
              id="outlined-basic"
              label="Line Number ID"
              variant="outlined"
              value={lineNumberID}
              onChange={handleChange(setlineNumberID)}
            />
            <TextField
              id="outlined-basic"
              label="Website Link"
              variant="outlined"
              value={websiteLink}
              onChange={handleChange(setwebsiteLink)}
            />
          </Box>

          <Button
            sx={{ marginBottom: "10px" }}
            size="medium"
            variant="contained"
            onClick={UpdateProfile}
            disabled={disabled || isLoading}
          >
            UPDATE
          </Button>
          {/* <FormHelperText error={true}>
            {disabled ? "Please fill all the required fields first" : ""}
          </FormHelperText> */}
        </Box>
        <Loader isLoading={isLoading} />
      </Header>
    </Fragment>
  );
};

export default Profile;
