import { db } from "../../../services/firebase";
import {
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import {
  categoryType,
  subCategoryType,
  brandType,
  dealType,
  loginUser,
  merchant_user,
} from "../../../types/dbDataTypes";


export const getAllQuery = async (dbCollection: string) => {
  try {
    let data: categoryType[] | subCategoryType[] | brandType[] | dealType[] | loginUser[]  = [];
    const querySnapshot = await getDocs(collection(db, dbCollection));
    querySnapshot.forEach((doc: any) => {
      data.push(doc.data());
    });
    return data;
  } catch (error: any) {
    console.warn(error.message);
    return false;
  }
};

export const getAllByAttributesQuery = async (
  collectionName: string,
  fieldAttr: string,
  fieldValue: string,
  operator: any
) => {
  try {
    const q = query(
      collection(db, collectionName),
      where(fieldAttr, operator, fieldValue),
      where("isArchive", "==", false)
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.size > 0) {
      let data: categoryType[] | subCategoryType[] | brandType[] | dealType[] | loginUser[] | merchant_user[] = [];
      querySnapshot?.docs.map((doc: any) => {
        data.push(doc.data());
      });
      return { response: true, data };
    } else {
      return { response: false };
    }
  } catch (error: any) {
    console.warn(error.message);
    return { response: false };
  }
};