import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./reduxSlice/LoginSlice";
import homeCardsReducer from "./reduxSlice/HomeCardsSlice";
import qrPaymentsReducer from "./reduxSlice/QRPaymentSlice";
import Coupon from "./reduxSlice/Coupon";
import MerchantInvoiceReducer from "./reduxSlice/MerchantInvoiceSlice";

export const store = configureStore({
  reducer: {
    login: loginReducer,
    homeCards: homeCardsReducer,
    QRPayment: qrPaymentsReducer,
    coupon: Coupon,
    merchantInvoices: MerchantInvoiceReducer
  },
});
