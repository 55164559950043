import { db } from "../../../services/firebase";

import {
  doc,
  setDoc,
  Timestamp
} from "firebase/firestore";
import { v4 as uuid } from "uuid";
import { getSessionData } from "../Auth/AuthStates";

export const setQuery = async (
  collection: any,
  data: any,
  collectionId: any
) => {
  try {
    let loginSession: any = getSessionData()
    let id = uuid().replaceAll("-", "");
    data.id = id;
    data = {
      ...data,
      merchant_id: data.merchant_id !== undefined ? loginSession.uid : loginSession.uid,
      merchant_email: loginSession.email,
      id: id,
      [collectionId]: id,
      // createdAt: Timestamp.fromDate(new Date()),
      isArchive: false,
      created_Date: new Date().getTime(),
    };

    await setDoc(doc(db, collection, id), data);

    return { response: true };
  } catch (error: any) {
    console.warn(error.message);
    return { response: false };
  }
};
