//! Cloud function url
//!     local
//!     dev
const url = "https://us-central1-deeperks-dev.cloudfunctions.net"
// const url = "http://49.228.226.87:5000"
//!     prod
//!                         C.F LINKS
export const GENERATE_SECURITY_CODE = `${url}/emailApi/sendOTP`
export const VERIFICATION_CODE = `${url}/emailApi/verificationCode`
export const SEND_GETSTARTED_OTP = `${url}/emailApi/sendGetStartedOTP`
export const VERIFY_QR_EMAIL = `${url}/emailApi/sendVerifyReceipt`
export const REJECT_QR_EMAIL = `${url}/emailApi/sendRejectReceipt`

export const HEADERS = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST",
    "Access-Control-Max-Age": "0",
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Headers": "Authorization",
};