import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/Header";
import AddIcon from "@mui/icons-material/Add";
import { Delete, Edit } from "@mui/icons-material";
import Popup from "../../Components/Popup";
import {
  setCouponData,
  getCouponData,
  updateCouponData,
  deleteCouponData,
} from "../../../store/reduxSlice/Coupon";
import { setDateFormate } from "../../utilities/Helper/setDateFormate";
import { getSession } from "../../utilities/Auth/AuthStates";
import { getObjectById } from "../../utilities/Helper/objectHelpers";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchBar from "../../Components/SearchBar";
import { cardHeaderStyles, modalStyles } from "../../Components/Style";
import DialogBox from "../../Components/Dialoag";
import { fetchDeals } from "../../../store/reduxSlice/dealSlice";
import { fetchBrand, setBrands } from "../../../store/reduxSlice/brandSlice";
import Loader from "../../Components/Loader";
import AlertDialog from "../../Components/ConfirmDelete";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../services/firebase";
import { CONSTANT, DB_COLLECTION } from "../../../constant";
import { getAllQuery } from "../../utilities/queries/getQuery";
import { toast } from "react-toastify";
import { convertToRaw, convertFromRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

type Props = {};
const Coupon = (props: Props) => {
  const data: any = useSelector((state: any) => state);
  const rows =
    data?.coupon && data?.coupon?.couponsData?.length > 0
      ? data?.coupon?.couponsData
      : [];
  const { couponsData } = data.coupon;
  console.log("rowsrows", couponsData);
  const [page, setPage] = React.useState(0);
  const [values, setValues] = useState<any>([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [disableButton, setDisableButton] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [textField, setTextField] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [catData, setCatData] = useState<any>([]);
  const [searchInput, setSearchInput] = useState("");
  const [subCatData, setSubCatData] = useState<any>([]);
  const [image, setImage] = React.useState<any>([]);
  const [prograssValue, setPrograssValue] = React.useState<any>([]);
  const [renderImageName, setRenderImageName] = React.useState<any>("");
  const [merchantArr, setmerchantArr] = useState([]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const [openAlert, setOpenAlert] = React.useState(false);
  const [itemToDelete, setItemToDelete] = React.useState<any>([]);
  const [convertedContent, setConvertedContent] = useState<any>(null);
  const [convertedContentTANDC, setConvertedContentTANDC] = useState<any>(null);
  const handleCloseAlert = () => {
    setOpenAlert(false);
    setItemToDelete([]);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [openModal, setOpenModal] = React.useState(false);

  const [editorState, setEditorState] = useState(() =>
  EditorState.createEmpty()
  );

  const [editorStateTANDC, setEditorStateTANDC] = useState(() =>
    EditorState.createEmpty()
  );

  const convertContentToHTML = () => {
    var rawJson = convertToRaw(editorState.getCurrentContent());
    console.log("state", rawJson);
    setConvertedContent(rawJson);
  };

  const convertContentToHTMLTANDC = () => {
    var rawJson = convertToRaw(editorStateTANDC.getCurrentContent());
    console.log("state", rawJson);
    setConvertedContentTANDC(rawJson);
  };

  const handleEditorChange = (state: any) => {
    setEditorState(state);
    convertContentToHTML();
    setDisableButton(false);
  };

  const handleEditorChangeTANDC = (state: any) => {
    setEditorStateTANDC(state);
    convertContentToHTMLTANDC();
    setDisableButton(false);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleClose = () => {
    setValues({
      ...values,
      title: "",
      discountPrice: "",
      code: "",
      merchant_id: "",
      priceAfterDiscount: 0,
      deeperkPercentage: 0,
      id: ""
    });
    setConvertedContent("");
    setConvertedContentTANDC("");
    setEditorState(EditorState.createEmpty());
    setEditorStateTANDC(EditorState.createEmpty());
    setImage({ QRPaymentImage: "", imageUrl: "" });
    setOpen(false);
    setIsEditMode(false);
    setDisableButton(false);
  };

  const dispatch: any = useDispatch();
  useEffect(() => {
    getSession();
    dispatch(getCouponData());
    getAllMerchant();
  }, []);

  const getAllMerchant = async () => {
    let res = await getAllQuery(DB_COLLECTION.DP_MERCHANT);
    if (res.error === undefined) {
      setmerchantArr(res);
    }
  };

  const columns = [
    {
      id: "title",
      label: "Title",
      minWidth: 100,
      type: "file",
    },
    {
      id: "referenceNo",
      label: "Reference No.",
      minWidth: 100,
    },
    {
      id: "discountPrice",
      label: "Discount Price",
      minWidth: 170,
    },
    {
      id: "priceAfterDiscount",
      label: "Price After Discount",
      minWidth: 170,
    },
    {
      id: "deeperkPercentage",
      label: "Deeperk Percentage",
      minWidth: 170,
    },
    {
      id: "purchaseCount",
      label: "Purchase Count",
      minWidth: 170,
    },
    {
      id: "code",
      label: "Code",
      placeholder: "",
      select: false,
    },
    {
      name: "coupan_description",
      label: "Coupon Description",
      options: {
        filter: true,
        sort: false,
        display: true
      }
    },
    {
      name: "terms_and_condition_description",
      label: "Terms and Condition Description",
      options: {
        filter: true,
        sort: false,
        display: false
      }
    },
    {
      id: "imageUrl",
      label: "Coupon Image",
      placeholder: "",
      select: false,
    },
    {
      id: "QRPaymentImage",
      label: "Payment QR",
      placeholder: "",
      select: false,
    },
    {
      id: "created_date",
      label: "Created Date",
      minWidth: 170,
      format: (value: number) => value.toFixed(2),
    },
    {
      id: "action",
      label: "Action",
      placeholder: "",
      type: "button",
      select: false,
    },
  ];

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "cat_id") {
      let filteredData = couponsData.filter(
        (item: any) => item?.cat_id === value
      );
      let locationArray = filteredData[0].location
        ? filteredData[0].location
        : [];
      setValues({
        ...values,
        location: locationArray,
        [name]: value,
      });
      setTextField(locationArray);
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }

    setLoading(false);
    setDisableButton(false);
  };

  const handleImage = (event: any) => {
    const { name, value, files } = event.target;
    if (files[0] != null) {
      setImage({
        ...image,
        [name]: files,
      });
      setDisableButton(false);
    } else {
      toast.error(`Select valid image extension!`, {
        className: "toast-message",
      });
    }
  };

  const inputFieldValues = [
    {
      name: "title",
      label: "Coupon Title",
      id: "title",
      placeholder: "Coupon Title",
      type: "text",
    },
    {
      name: "Price",
      label: "Coupon Price",
      id: "discountPrice",
      placeholder: "Coupon price",
      type: "number",
    },
    {
      name: "priceAfterDiscount",
      label: "Price After Discount",
      id: "priceAfterDiscount",
      placeholder: "Price After Discount",
      type: "number",
    },
    {
      name: "deeperkPercentage",
      label: "Deeperk Percentage",
      id: "deeperkPercentage",
      placeholder: "Deeperk Percentage",
      type: "number",
    },
    {
      name: "code",
      label: "Coupon Code",
      id: "code",
      placeholder: "Coupon Code",
      type: "text",
    },
    {
      id: "imageUrl",
      name: "imageUrl",
      label: "Coupon Image",
      type: "file",
    },
    {
      id: "QRPaymentImage",
      name: "QRPaymentImage",
      label: "Payment QR",
      type: "file",
    },
    {
      id: "coupan_description",
      name: "coupan_description",
      label: "Coupon Description",
      placeholder: "",
      type: "text",
      // required: true,
    },
    {
      id: "terms_and_condition_description",
      name: "terms_and_condition_description",
      label: "Terms and Condition Description",
      placeholder: "",
      type: "text",
      // required: true,
    },
  ];

  const getContent = () => (
    <Box sx={modalStyles.inputFields}>
      {window.localStorage.getItem(CONSTANT.DEEPERKS_SESSION_ADMIN) ===
        "true" && ( // This dropdown only for admin
        <div style={{ display: "flex" }}>
          <FormControl fullWidth={true}>
            <InputLabel id="demo-simple-select-label">
              Select Merchant *
            </InputLabel>
            <Select
              required
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="merchant_id"
              label="Merchant*"
              defaultValue={values.merchant_id}
              value={values.merchant_id}
              variant="outlined"
              onBlur={handleChange}
              onChange={handleChange}
            >
              {merchantArr && merchantArr.length > 0 ? (
                merchantArr.map((marchant: any) => {
                  return (
                    <MenuItem
                      // key={eachCategory.cat_id}
                      // value={`${eachCategory.cat_id}`}
                      key={marchant.id}
                      value={marchant.id}
                    >
                      {marchant.merchantName}
                    </MenuItem>
                  );
                })
              ) : (
                <MenuItem> </MenuItem>
              )}
            </Select>
          </FormControl>
        </div>
      )}

      {/* <div>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Location"
          name={"location"}
          value={textField}
          disabled
          onChange={(event: any) => handleChange(event)}
        />
      </div> */}
      {/* <div style={{ display: "flex" }}>
        <FormControl fullWidth={true}>
          <InputLabel id="demo-simple-select-label">Category Id *</InputLabel>
          <Select
            required
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="cat_id"
            label="Category Id*"
            defaultValue={values["cat_id"]}
            variant="outlined"
            onBlur={handleChange}
            onChange={handleChange}
          >
            {couponsData && couponsData.length > 0 ? (
              couponsData.map((eachCategory: any) => {
                return (
                  <MenuItem
                    // key={eachCategory.cat_id}
                    // value={`${eachCategory.cat_id}`}
                    key={
                      isEditMode && values["cat_id"] === eachCategory["cat_id"]
                        ? eachCategory["cat_id"]
                        : eachCategory["cat_id"]
                    }
                    value={
                      isEditMode && values["cat_id"] === eachCategory["cat_id"]
                        ? eachCategory["cat_id"]
                        : eachCategory["cat_id"]
                    }
                  >
                    {eachCategory.cat_name}
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem> </MenuItem>
            )}
          </Select>
        </FormControl>
      </div> */}
      
      {inputFieldValues.map((eachField: any) =>
          eachField.id === "coupan_description" ||
          eachField.id === "terms_and_condition_description" ? (
            <>
              <InputLabel
                id="demo-simple-select-label"
                style={{ marginBottom: "10px" }}
              >
                {eachField.label}
              </InputLabel>
              <Editor
                editorState={
                  eachField.id === "coupan_description"
                    ? editorState
                    : editorStateTANDC
                }
                onEditorStateChange={
                  eachField.id === "coupan_description"
                    ? handleEditorChange
                    : handleEditorChangeTANDC
                }
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
              />
            </>
          ) : (
            <>
              <InputLabel style={{ margin: "5px 5px" }}>
                {eachField.label}
              </InputLabel>
              <TextField
                // fullWidth={eachField.id === "homeCard_description" ? false : true}
                defaultValue={
                  eachField.type === "file" ? "" : values[eachField.id]
                }
                placeholder={eachField.placeholder}
                name={eachField.id}
                type={eachField.type}
                // multiline={eachField.id === "homeCard_description" ? true : false}
                onChange={(event: any) => {
                  eachField.type === "file"
                    ? handleImage(event)
                    : handleChange(event);
                }}
              />
            </>
          )
        )}
    </Box>
  );

  const handleFormSubmit = async (file: any) => {
    if (
      image &&
      values.title &&
      values.title !== undefined &&
      values.title !== "undefined" &&

      values.discountPrice &&
      values.discountPrice !== undefined &&
      values.discountPrice !== "undefined" &&

      values.code &&
      values.code !== undefined &&
      values.code !== "undefined" &&

      values.merchant_id &&
      values.merchant_id !== undefined &&
      values.merchant_id !== "undefined" &&

      convertedContent &&
      convertedContent != undefined &&
      convertedContent != "undefined" &&

      convertedContentTANDC &&
      convertedContentTANDC != undefined &&
      convertedContentTANDC != "undefined" &&

      values.priceAfterDiscount &&
      values.priceAfterDiscount != undefined &&
      values.priceAfterDiscount != "undefined" &&

      values.deeperkPercentage &&
      values.deeperkPercentage != undefined &&
      values.deeperkPercentage != "undefined"
    ) {
      setLoading(true);
      let couponData: any = {};
      if (image) {
        for (const key in image) {
          const element = image[key];
          if (key) {
            let imageName = Math.round(Math.random() * 1000000000);
            const storageRef = ref(storage, `/Coupons/${key}/${imageName}`);
            const uploadTask = uploadBytesResumable(storageRef, element[0]);
            const getThumbnailUrl = await uploadImageTask(uploadTask);
            values[key] = getThumbnailUrl;
            couponData = {
              ...values,
            };
          }
        }
        // homeCard_description: descriptionItem,
        if (couponData) {
          couponData = {
            ...values,
            referenceNo: Math.round((Math.random() * 100000000)),
            purchaseCount: 0,
            terms_and_condition_description: convertedContentTANDC,
            coupan_description: convertedContent,
          };
          dispatch(setCouponData(couponData));
          handleClose();
          dispatch(getCouponData());
          setLoading(false);
        }
      }
    } else {
      // setLoading(true);
      setDisableButton(true);
    }
  };

  const handleFormSubmitAfterEdit = async (file: any) => {
    console.log("inside update");
    if (
      image
      // && values.homeCard_link.length > 0
    ) {
      let couponData = {
        ...values,
      };
      if (image) {
        setLoading(true);
        if (
          image.hasOwnProperty("imageUrl") &&
          image.hasOwnProperty("QRPaymentImage") &&
          typeof image.imageUrl === "object" &&
          typeof image.QRPaymentImage === "object"
        ) {
          for (const key in image) {
            const element = image[key];
            if (key) {
              let imageName = Math.round(Math.random() * 1000000000);
              const storageRef = ref(storage, `/Coupons/${key}/${imageName}`);
              const uploadTask = uploadBytesResumable(storageRef, element[0]);
              const getThumbnailUrl = await uploadImageTask(uploadTask);
              values[key] = getThumbnailUrl;
              couponData = {
                ...values,
              };
            }
          }
        }

        if (couponData) {
          couponData = {
            ...values,
            terms_and_condition_description: convertedContentTANDC,
            coupan_description: convertedContent,
          };
          await dispatch(updateCouponData(couponData));
          handleClose();
          await dispatch(getCouponData());
          setLoading(false);
          setIsEditMode(false);
        }
      }
    } else {
      setDisableButton(true);
      setIsEditMode(false);
    }
  };

  async function uploadImageTask(uploadTask: any) {
    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot: any) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        (error: any) => {
          alert(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        }
      );
    });
  }
  // const handleFormSubmitAfterEdit = async (file: any) => {
  //   console.log("inside update", values);
  //   if (image) {
  //     let setDataRequest = {
  //       ...values,
  //     };

  //     if (image) {
  //       setLoading(true);

  //       if (
  //         image.hasOwnProperty("sub_cat_icon") &&
  //         typeof image.sub_cat_icon === "object"
  //       ) {
  //         for (const key in image) {
  //           const element = image[key];
  //           if (key === "sub_cat_icon") {
  //             let imageName = Math.round(Math.random() * 1000000000);
  //             let brandName =
  //               values && values.sub_cat_icon ? values.sub_cat_icon : "custom";
  //             const storageRef = ref(
  //               storage,
  //               `/Coupon/${brandName}/${imageName}-${brandName}-sub_cat_icon`
  //             );
  //             const uploadTask = uploadBytesResumable(storageRef, element[0]);
  //             const getThumbnailUrl = await uploadImageTask(uploadTask);
  //             values["sub_cat_icon"] = getThumbnailUrl;
  //             setDataRequest = {
  //               ...values,
  //             };
  //           }
  //         }
  //       }  else if (
  //         image.hasOwnProperty("sub_cat_icon") &&
  //         typeof image.sub_cat_icon === "object"
  //       ) {
  //         let imageName = Math.round(Math.random() * 1000000000);
  //         let brandName = values && values.sub_cat_icon ? values.sub_cat_icon : "custom";
  //         const storageRef = ref(
  //           storage,
  //           `/Coupon/${brandName}/${imageName}-${brandName}-sub_cat_icon`
  //         );
  //         const uploadTask = uploadBytesResumable(
  //           storageRef,
  //           image.sub_cat_icon[0]
  //         );
  //         const getThumbnailUrl = await uploadImageTask(uploadTask);
  //         values["sub_cat_icon"] = getThumbnailUrl;
  //         setDataRequest = {
  //           ...values,
  //         };
  //       }

  //       if (setDataRequest.sub_cat_icon) {
  //         setDataRequest = {
  //           ...values,
  //         };
  //         await dispatch(updateCouponData(setDataRequest));
  //         handleClose();
  //         await dispatch(getCouponData());
  //         setLoading(false);
  //         setIsEditMode(false);
  //       }
  //     }
  //   } else {
  //     setDisableButton(true);
  //     setIsEditMode(false);
  //   }
  // };

  const handleEditHomeCards = (data: any) => {
    setIsEditMode(true);
    setValues({
      ...values,
      merchant_id: data?.merchant_id || "",
      code: data?.code || "",
      discountPrice: data?.discountPrice || "",
      title: data?.title || "",
      id: data?.id,
      oupan_description: data?.coupan_description || "",
      terms_and_condition_description:
        data?.terms_and_condition_description || "",
      priceAfterDiscount: data?.priceAfterDiscount || "",
      deeperkPercentage: data?.deeperkPercentage || "",
    });
    if (data.coupan_description) {
      setEditorState(
        EditorState.createWithContent(convertFromRaw(data.coupan_description))
      );
      setConvertedContent(data.coupan_description);
    }
    if (data.terms_and_condition_description) {
      setEditorStateTANDC(
        EditorState.createWithContent(
          convertFromRaw(data.terms_and_condition_description)
        )
      );
      setConvertedContentTANDC(data.terms_and_condition_description);
    }
    setImage({
      QRPaymentImage: data?.QRPaymentImage,
      imageUrl: data?.imageUrl
    });
    handleOpen();
  };

  const handleConfirmDelete = async () => {
    setLoading(true);
    await dispatch(deleteCouponData(itemToDelete[0]));
    await dispatch(getCouponData());
    setItemToDelete([]);
    setLoading(false);
    handleCloseAlert();
  };

  const handleChangeSearch = (e: any) => {
    let filteredData: any = [];
    let filterCat: any = [];
    e.preventDefault();
    if (e.target.value?.length > 0) {
      filteredData = rows.filter((row: any) => {
        return (
          row.sub_cat_name
            ?.toLowerCase()
            .match(e.target.value?.toLowerCase()) ||
          row.sub_cat_description
            ?.toLowerCase()
            ?.match(e.target.value.toLowerCase())
        );
      });
      // filter by location
      rows.filter((row: any) => {
        if (
          row.location.some((x: any) =>
            x.toLowerCase().includes(e.target.value?.toLowerCase())
          )
        ) {
          filteredData?.push(row);
        }
      });

      if (filteredData.length === 0) {
        filterCat = couponsData.filter((row: any) => {
          return row.cat_name
            ?.toLowerCase()
            .match(e.target.value?.toLowerCase());
        });
        filterCat.map((item: any) => {
          let data = rows.filter((item2: any) => item2.cat_id === item?.cat_id);
          filteredData.push(data[0]);
        });
      }
      setSearchInput(e.target.value);
      setSubCatData(filteredData);
    } else {
      setSubCatData(rows);
    }
  };
  const handleDelete = (item: any) => {
    setItemToDelete([item]);
    setOpenAlert(true);
  };
  const dialogContent =
    "Are you sure you want to delete this?";

  return (
    <>
      <Header>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ fontSize: "2em" }}>Coupons</div>
        </div>
      </Header>
      <Box sx={cardHeaderStyles.wrapper}>
        {/* Search Bar */}
        <SearchBar
          onChange={handleChangeSearch}
          value={searchInput}
          placeholder="Search by coupon Name or Coupon Description"
          searchBarWidth="720px"
        />
        <Box>
          <div>
            {/* <Button variant="contained" onClick={handleOpen}>
              <AddIcon />
              Add Coupon
            </Button> */}
            <IconButton>
              <RefreshIcon />
            </IconButton>

            <div>
              <Popup
                open={open}
                onClose={handleClose}
                title={isEditMode ? "Edit Coupon" : "Add Coupon"}
                buttonText={isEditMode ? "Update Coupon" : "Submit Coupon"}
                content={getContent()}
                values={values}
                handleFormSubmit={() => handleFormSubmit(image)}
                disabled={disableButton}
                isLoading={isLoading}
                isEditMode={isEditMode}
                handleFormSubmitAfterEdit={() =>
                  handleFormSubmitAfterEdit(image)
                }
              />
            </div>
          </div>
        </Box>
      </Box>
      {/*  Table Start */}
      <div
        style={{ marginLeft: "5%", display: "flex", justifyContent: "center" }}
      >
        <Grid container>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 700 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        align={"center"}
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: "bold",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows && rows.length > 0 ? (
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row: any) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            // key={row.code}
                          >
                            <TableCell align={"center"}>{row.title}</TableCell>
                            <TableCell key={"description"} align={"center"}>
                              {row?.referenceNo}
                            </TableCell>
                            <TableCell key={"description"} align={"center"}>
                              {row?.discountPrice}
                            </TableCell>
                            <TableCell key={"description"} align={"center"}>
                              {row?.priceAfterDiscount}
                            </TableCell>
                            <TableCell key={"description"} align={"center"}>
                              {row?.deeperkPercentage}
                            </TableCell>
                            <TableCell key={"description"} align={"center"}>
                              {row?.purchaseCount}
                            </TableCell>
                            <TableCell key={"description"} align={"center"}>
                              {row?.code}
                            </TableCell>
                            <TableCell key={"description"} align={"center"}>
                              {row?.coupan_description && row?.coupan_description?.blocks[0].text || ""}
                            </TableCell>
                            <TableCell key={"description"} align={"center"}>
                              {row?.terms_and_condition_description && row?.terms_and_condition_description?.blocks[0].text || ""}
                            </TableCell>
                            <TableCell align={"center"}>
                              <img
                                src={row.imageUrl}
                                style={{ width: "70px", height: "65px" }}
                              />
                            </TableCell>
                            <TableCell align={"center"}>
                              <img
                                src={row.QRPaymentImage}
                                style={{ width: "70px", height: "65px" }}
                              />
                            </TableCell>
                            <TableCell align={"center"}>
                              {setDateFormate(row?.created_Date)}
                            </TableCell>
                            <TableCell align={"center"}>
                              <Button
                                sx={{ margin: "2px" }}
                                variant="outlined"
                                onClick={() =>
                                  handleEditHomeCards(row)
                                }
                              >
                                <Edit fontSize="small" />
                              </Button>
                              <Button
                                sx={{ margin: "2px" }}
                                variant="outlined"
                                onClick={() => handleDelete(row)}
                              >
                                <Delete fontSize="small" />
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <Typography
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: 15,
                        right: "40%",
                      }}
                    >
                      No Coupon Found
                    </Typography>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
        <AlertDialog
          openAlert={openAlert}
          handleCloseAlert={handleCloseAlert}
          handleConfirmDelete={handleConfirmDelete}
          isLoading={isLoading}
        />
        <Loader isLoading={isLoading} />

        <DialogBox
          openModal={openModal}
          handleModalClose={handleModalClose}
          dialogContent={dialogContent}
        ></DialogBox>
      </div>
    </>
  );
};

export default Coupon;
