import {
  onAuthStateChanged,
  updateProfile,
  signOut,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { WEB_CONFIG } from "../../../config/web_config";
import { CONSTANT, ROUTES } from "../../../constant";
import { auth } from "../../../services/firebase";
import { printLogs } from "../Helper/ErrorHandlers";
import { setQuery } from "../queries/setQuery";

export const getSession = () => {
  onAuthStateChanged(auth, (user) => {
    let merchant_ID = localStorage.getItem(CONSTANT.MERCHANT_DATA);
    printLogs(`merchant ID: ${merchant_ID}`);
    printLogs(`user session: ${JSON.parse(JSON.stringify(user))}`);
    if (!user) window.location.href = `${WEB_CONFIG.base_url}${ROUTES.LOGIN}`;
    if (
      user?.photoURL !== "MERCHANT" ||
      merchant_ID === null ||
      merchant_ID === undefined ||
      merchant_ID === "null"
    ) {
      signOutUser();
    }
  });
};

export const isUserLogin = () => {
  onAuthStateChanged(auth, (user) => {
    let merchant_ID = localStorage.getItem(CONSTANT.MERCHANT_DATA);
    printLogs(`user seesion:  ${JSON.parse(JSON.stringify(user))}`);
    if (
      user &&
      user?.photoURL === "MERCHANT" &&
      (merchant_ID !== null ||
        merchant_ID !== undefined ||
        merchant_ID !== "null")
    )
      window.location.href = `${WEB_CONFIG.base_url}${ROUTES.HOME}`;
  });
};

export const updateUserProfile = () => {
  const user: any = getSessionData();
  if (user) {
    updateProfile(user, {
      displayName: "Jane Q. User",
      photoURL: "https://example.com/jane-q-user/profile.jpg",
    })
      .then(() => {
        // Profile updated!
        // ...
      })
      .catch((error) => {
        // An error occurred
        // ...
      });
  }
};

export const getSessionData = () => {
  const user = auth.currentUser;
  if (user) return JSON.parse(JSON.stringify(user));
  else return false;
};

export const getLoginUserId = () => {
  onAuthStateChanged(auth, (user: any) => {
    new Promise((resolve: any) => {
      if (user) return resolve(user);
      else return resolve(false);
    });
  });
};

export const signOutUser = () => {
  signOut(auth)
    .then(() => {
      window.localStorage.clear();
      window.location.href = `${WEB_CONFIG.base_url}${ROUTES.LOGIN}`;
    })
    .catch((error) => {
      console.warn(error);
    });
};

export const createUser = (data: any, collectionName: string) => {
  new Promise((resolve: any) => {
    createUserWithEmailAndPassword(auth, data.email, data.email)
      .then(async (userCredential) => {
        const user = userCredential.user;
        let res: any = await setQuery(
          collectionName,
          { ...data, id: user.uid },
          user.uid
        );
        resolve(res);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  });
};

export const GetUser = () => {};
