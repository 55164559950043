import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ROUTES } from "../constant";
//Import Screens
import Home from "../UI/Pages/Home/Home";
import LoginForm from "../UI/Pages/Login/LoginForm";
import HomeCards from "../UI/Pages/HomeCards/HomeCards";
import QRPayment from "../UI/Pages/QRPayment/QRPayment";
import Profile from "../UI/Pages/Profile/Profile";
import Coupon from "../UI/Pages/Coupon/Coupon";
import MerchantInvoice from "../UI/Pages/MerchantInvoices/MerchantInvoices";

const Navigation: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTES.HOME} element={<Profile />} />
        <Route path={ROUTES.LOGIN} element={<LoginForm />} />
        <Route path={ROUTES.HOME_CARDS} element={<HomeCards />} />
        <Route path={ROUTES.QR_PAYMENTS} element={<QRPayment />} />
        <Route path={ROUTES.COUPONS} element={<Coupon />} />
        <Route path={ROUTES.MERCHANT_INVOICES} element={<MerchantInvoice />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Navigation;
