import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { ROUTES, TEXT } from "../../constant";
import { signOutUser } from "../utilities/Auth/AuthStates";
import { useNavigate } from "react-router-dom";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import { Tooltip } from "@mui/material";
import QrCodeIcon from "@mui/icons-material/QrCode";
import Person2Icon from "@mui/icons-material/Person2";
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Header({ children }: any) {
  let navigate = useNavigate();

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {TEXT.benefithub_title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {[
            "Profile",
            "Coupon",
            "Home Cards",
            "Payments",
            "Merchant Invoices",
            // "Category",
            // "Sub Category",
            // "Brand",
            // "Deals",
            // "Slider",
            // "Videos",
            // "Companies",
            // "Merchant",
            // "Users",
          ].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => {
                  {
                    index === 0
                      ? navigate(ROUTES.HOME) 
                      : index === 1 
                      ? navigate(ROUTES.COUPONS)
                      : index === 2
                      ? navigate(ROUTES.HOME_CARDS)
                      : index === 3
                      ? navigate(ROUTES.QR_PAYMENTS)
                      : index === 4
                      ? navigate(ROUTES.MERCHANT_INVOICES)
                      
                    //   : index === 4
                    //   ? navigate(ROUTES.SLIDER)
                    //   : index === 5
                    //   ? navigate(ROUTES.HOME_CARDS)
                    //   : index === 6
                    //   ? navigate(ROUTES.VIDEOS)
                    //   : index === 7
                    //   ? navigate(ROUTES.COMPANIES)
                    //   : index === 8
                    //   ? navigate(ROUTES.MERCHANT)
                    //   : index === 9
                    //   ? navigate(ROUTES.USER_REFFERAL)
                    //   : index === 10
                    //   ? navigate(ROUTES.QR_PAYMENTS)
                      : navigate(ROUTES.HOME);
                  }
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {index === 0 ? (
                    <Tooltip title="Profile" placement="right-start">
                      <Person2Icon />
                    </Tooltip>
                  ) : index === 1 ? (
                    <Tooltip title="Coupon" placement="right-start">
                      <LocalActivityIcon />
                    </Tooltip>
                  ) : index === 2 ? (
                    <Tooltip title="Home Cards" placement="right-start">
                      <AutoAwesomeMotionIcon />
                    </Tooltip>
                  ) : index === 3 ? (
                    <Tooltip title="QR Payments" placement="right-start">
                      <QrCodeIcon />
                    </Tooltip>
                  ) : index === 4 ? (
                    <Tooltip title="Merchant Invoices" placement="right-start">
                      <PointOfSaleIcon />
                    </Tooltip>
                  ) : (
                    ""
                  )}

                  {/* {index === 0 ? (
                    <Tooltip title="Add Category" placement="right-start">
                      <CategoryIcon />
                    </Tooltip>
                  ) : index === 1 ? (
                    <Tooltip title="Sub Category" placement="right-start">
                      <BallotIcon />
                    </Tooltip>
                  ) : index === 2 ? (
                    <Tooltip title="Brands" placement="right-start">
                      <LocalOfferIcon />
                    </Tooltip>
                  ) : index === 3 ? (
                    <Tooltip title="Deals" placement="right-start">
                      <HandshakeIcon />
                    </Tooltip>
                  ) : index === 4 ? (
                    <Tooltip title="Slider" placement="right-start">
                      <ViewCarouselIcon />
                    </Tooltip>
                  ) : index === 5 ? (
                    <Tooltip title="Home Cards" placement="right-start">
                      <AutoAwesomeMotionIcon />
                    </Tooltip>
                  ) : index === 6 ? (
                    <Tooltip title="Videos" placement="right-start">
                      <VideoLibraryIcon />
                    </Tooltip>
                  ) : index === 7 ? (
                    <Tooltip title="Companies" placement="right-start">
                      <BusinessIcon />
                    </Tooltip>
                  ) : index === 8 ? (
                    <Tooltip title="Merchant" placement="right-start">
                      <StorefrontIcon />
                    </Tooltip>
                  ) : index === 9 ? (
                    <Tooltip title="Users" placement="right-start">
                      <GroupIcon />
                    </Tooltip>
                  ) : index === 10 ? (
                    <Tooltip title="QR Payments" placement="right-start">
                      <QrCodeIcon />
                    </Tooltip>
                  ) : (
                    ""
                  )} */}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => {
                signOutUser();
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary={"Logout"} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />

        {children}
      </Box>
    </Box>
  );
}
