import React, {
  Fragment,
  createContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { getMerchantInvoiceData } from "../../../store/reduxSlice/MerchantInvoiceSlice";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/Header";
import {
  Button,
  CssBaselineProps,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import VerifiedIcon from "@mui/icons-material/Verified";
import Utiles from "./Utiles";
import { getSession } from "../../utilities/Auth/AuthStates";
import { setDateFormate } from "../../utilities/Helper/setDateFormate";
import ReportOffIcon from "@mui/icons-material/ReportOff";
import SearchBar from "../../Components/SearchBar";
import UploadModal from "../../Components/UploadModal";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { CONSTANT } from "../../../constant";
import ImagesModal from "../../Components/ImagesModal";
import PreviewIcon from "@mui/icons-material/Preview";
import ImageModal from "../../Components/ImagePreview";

export interface IAppProps {}

export default function MerchantInvoice(props: IAppProps) {
  const dispatch: any = useDispatch();
  const data: any = useSelector((state: any) => state);
  const {
    columns,
    rowsPerPage,
    page,
    isModalOpen,
    selectedPayment,
    containerStyle,
    imageStyle,
    iconOverlayStyle,
    uploadType,
    isImageModal,
    imageUrl,
    isVerfyRecipt,
    openImage,
    isLoading,
    verifyRecipt,
    rejectRecipt,
    handleCloseModalImage,
    handleOpenModalImage,
    setimageUrl,
    handleImage,
    setIsHovered,
    handleChangeRowsPerPage,
    handleChangePage,
    handleChangeModal,
  } = Utiles();

  const [ddIsPay, setddIsPay] = useState<string>("All");
  const [ddMonth, setddMonth] = useState<string>("All");
  const [ddYear, setddYear] = useState<string>("All");
  const [searchtxt, setsearchtxt] = useState<string>("");
  const [merchantInvoicesFiltered, setmerchantInvoicesFiltered] = useState<any>(
    []
  );

  const filtersValues = useMemo(() => {
    return { ddIsPay, ddMonth, ddYear, searchtxt };
  }, [ddIsPay, ddMonth, ddYear, searchtxt]);

  let invoiceData: any =
    data?.merchantInvoices &&
    data?.merchantInvoices?.MerchantInvoiceData.length > 0
      ? data?.merchantInvoices?.MerchantInvoiceData
      : [];

  // get all merchantInvoices
  useEffect(() => {
    getSession();
    dispatch(getMerchantInvoiceData());
  }, []);

  // Filter usememo
  useMemo(() => {
    if (invoiceData.length > 0) {
      let tableData: any = invoiceData;

      tableData = tableData.filter((item: any) => {
        if (filtersValues.ddIsPay === "Y" && item.isPaid === true) {
          return item;
        } else if (filtersValues.ddIsPay === "N" && item.isPaid === false) {
          return item;
        } else if (filtersValues.ddIsPay === "All") {
          return item;
        }
      });

      tableData = tableData.filter((item: any) => {
        if (filtersValues.ddMonth === "All") {
          return item;
        } else if (
          Number(filtersValues.ddMonth) ===
          Number(new Date(item.created_Date).getMonth() + 1)
        ) {
          return item;
        }
      });

      tableData = tableData.filter((item: any) => {
        if (filtersValues.ddYear === "All") {
          return item;
        } else if (
          Number(filtersValues.ddYear) ===
          Number(new Date(item.created_Date).getFullYear())
        ) {
          return item;
        }
      });

      tableData = tableData.filter((item: any) => {
        if (filtersValues.searchtxt === "") {
          return item;
        } else if (
          item.merchantName?.toLowerCase().includes(searchtxt.toLowerCase())
        ) {
          return item;
        }
      });

      setmerchantInvoicesFiltered(tableData);
    }
  }, [filtersValues, invoiceData]);

  return (
    <Fragment>
      <Header>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ fontSize: "2em" }}>Merchant Inovices</div>
        </div>
      </Header>

      <div
        style={{ marginLeft: "5%", display: "flex", justifyContent: "center" }}
      >
        <Grid container>
          <Paper sx={{ display: "flex", width: "100%", overflow: "hidden" }}>
            <FormControl
              fullWidth={true}
              sx={{ marginTop: "10px", marginRight: "10px", width: "15%" }}
            >
              <InputLabel id="demo-simple-select-label">Is Paid</InputLabel>
              <Select
                required
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Is Paid"
                variant="outlined"
                onChange={(e: any) => {
                  setddIsPay(e.target.value);
                }}
              >
                <MenuItem value={"All"}>All</MenuItem>
                <MenuItem value={"Y"}>Paid</MenuItem>
                <MenuItem value={"N"}>Not Paid</MenuItem>
              </Select>
            </FormControl>

            <FormControl
              fullWidth={true}
              sx={{ marginTop: "10px", marginRight: "10px", width: "15%" }}
            >
              <InputLabel id="demo-simple-select-label">
                Invoice Month
              </InputLabel>
              <Select
                required
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Month"
                variant="outlined"
                onChange={(e: any) => {
                  setddMonth(e.target.value);
                }}
              >
                <MenuItem key={0} value={"All"}>
                  All
                </MenuItem>
                <MenuItem key={1} value={"1"}>
                  Jan
                </MenuItem>
                <MenuItem key={2} value={"2"}>
                  Feb
                </MenuItem>
                <MenuItem key={3} value={"3"}>
                  Mar
                </MenuItem>
                <MenuItem key={4} value={"4"}>
                  Apr
                </MenuItem>
                <MenuItem key={5} value={"5"}>
                  May
                </MenuItem>
                <MenuItem key={6} value={"6"}>
                  Jun
                </MenuItem>
                <MenuItem key={7} value={"7"}>
                  Jul
                </MenuItem>
                <MenuItem key={8} value={"8"}>
                  Aug
                </MenuItem>
                <MenuItem key={9} value={"9"}>
                  Sep
                </MenuItem>
                <MenuItem key={10} value={"10"}>
                  Oct
                </MenuItem>
                <MenuItem key={11} value={"11"}>
                  Nov
                </MenuItem>
                <MenuItem key={12} value={"12"}>
                  Dec
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl
              fullWidth={true}
              sx={{ marginTop: "10px", marginRight: "10px", width: "15%" }}
            >
              <InputLabel id="demo-simple-select-label">
                Invoice Year
              </InputLabel>
              <Select
                required
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Year"
                variant="outlined"
                onChange={(e: any) => {
                  setddYear(e.target.value);
                }}
              >
                <MenuItem key={0} value={"All"}>
                  All
                </MenuItem>
                <MenuItem key={1} value={"2023"}>
                  2023
                </MenuItem>
                <MenuItem key={2} value={"2024"}>
                  2024
                </MenuItem>
                <MenuItem key={3} value={"2025"}>
                  2025
                </MenuItem>
                <MenuItem key={4} value={"2026"}>
                  2026
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl
              fullWidth={true}
              sx={{ marginTop: "10px", marginRight: "10px", width: "30%" }}
            >
              <SearchBar
                onChange={(e: any) => setsearchtxt(e.target.value)}
                value={searchtxt}
                placeholder="Search..."
                searchBarWidth="100%"
              />
            </FormControl>
          </Paper>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns?.map((column: any) => (
                      <TableCell
                        align={"center"}
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: "bold",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {merchantInvoicesFiltered &&
                  merchantInvoicesFiltered.length > 0 ? (
                    merchantInvoicesFiltered
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row: any, index: any) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.index}
                          >
                            <TableCell align={"center"}>
                              {row?.merchantName}
                            </TableCell>
                            <TableCell align={"center"}>
                              {row?.invoiceAmount}
                            </TableCell>
                            <TableCell align={"center"}>
                              {setDateFormate(row?.created_Date)}
                            </TableCell>
                            <TableCell align={"center"}>
                              {row.isPaid === true && (
                                <VerifiedIcon color="success" />
                              )}
                              {row.isPaid === false && (
                                <CancelIcon color="error" />
                              )}
                            </TableCell>
                            <TableCell align={"center"}>
                              {row.receiptVerficationDateTime
                                ? setDateFormate(row.receiptVerficationDateTime)
                                : "--/--/----"}
                            </TableCell>
                            <TableCell align={"center"}>
                              {row.isUploadReceipt === true && (
                                <VerifiedIcon color="success" />
                              )}
                              {row.isUploadReceipt === false && (
                                <CancelIcon color="error" />
                              )}
                            </TableCell>
                            <TableCell align={"center"}>
                              {row.uploadReceiptDateTime
                                ? setDateFormate(row.uploadReceiptDateTime)
                                : "--/--/----"}
                            </TableCell>

                            <TableCell align={"center"}>
                              {row.receiptRejectReason === undefined && (
                                <CancelIcon color="success" />
                              )}
                              {row.receiptRejectReason &&
                                row.receiptRejectReason}
                            </TableCell>

                            <TableCell
                              align={"center"}
                              key={"paymentQRImage"}
                              style={{ width: "120px" }}
                            >
                              {row?.paymentQRImage ? (
                                <div
                                  style={containerStyle}
                                  onMouseEnter={() => setIsHovered(true)}
                                  onMouseLeave={() => setIsHovered(false)}
                                  onClick={() => {
                                    handleImage();
                                    setimageUrl([row?.paymentQRImage]);
                                  }}
                                >
                                  <img
                                    alt="paymentQRImage"
                                    src={row?.paymentQRImage}
                                    style={imageStyle}
                                  />
                                  <div style={iconOverlayStyle}>
                                    <ZoomInIcon fontSize="large" />
                                  </div>
                                </div>
                              ) : (
                                <ReportOffIcon />
                              )}
                            </TableCell>

                            <TableCell
                              align={"center"}
                              key={"paymentReceiptImage"}
                              style={{ padding: "5px"}}
                            >
                              {row?.paymentReceiptImage ? (
                                <img
                                  alt="paymentReceiptImage"
                                  src={row?.paymentReceiptImage}
                                  style={{ width: "90px" }}
                                />
                              ) : (
                                <ReportOffIcon />
                              )}
                            </TableCell>

                            <TableCell align={"center"}>
                              {/* QR */}
                              {/* <Button
                                sx={{ margin: "2px" }}
                                variant="outlined"
                                onClick={() =>
                                  handleChangeModal(row, CONSTANT.QR_UPLOAD)
                                }
                              >
                                <UploadFileIcon
                                  color={"info"}
                                  fontSize="small"
                                />
                              </Button> */}

                              {/* Receipt */}
                              <Button
                                sx={{ margin: "2px" }}
                                variant="outlined"
                                onClick={() =>
                                  handleChangeModal(
                                    row,
                                    CONSTANT.RECEIPT_UPLOAD
                                  )
                                }
                              >
                                <UploadFileIcon fontSize="small" />
                              </Button>

                              <Button
                                sx={{ margin: "2px" }}
                                variant="outlined"
                                onClick={() =>
                                  handleOpenModalImage(row, row?.isVerify)
                                }
                              >
                                <PreviewIcon color="success" />
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <Typography
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: 15,
                        right: "40%",
                      }}
                    >
                      No Invoice Found
                    </Typography>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={merchantInvoicesFiltered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </div>

      <UploadModal
        isOpen={isModalOpen}
        handleChangeModal={handleChangeModal}
        invoiceData={selectedPayment}
        uploadType={uploadType}
      />

      <ImagesModal
        images={imageUrl}
        isOpen={isImageModal}
        onClose={handleImage}
      />

      {/* for receipt verification */}
      <ImageModal
        open={openImage}
        handleCloseModal={handleCloseModalImage}
        imageUrl={imageUrl}
        isActionButton={true}
        actionTitle={"Verify"}
        ActionFunction={verifyRecipt}
        isActionButton2={true}
        actionTitle2={"Reject"}
        ActionFunction2={rejectRecipt}
        isActionButtonAllow={!isVerfyRecipt}
        isLoading={isLoading}
      />
    </Fragment>
  );
}
