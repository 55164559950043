// // DEV
// export const WEB_CONFIG: any = {
//     base_url: "http://localhost:3000",
//     // base_url: "https://deeperks-merchant-dev.web.app",
//     marketplace_base_url: "https://deeperks-marketplace-dev.web.app",
//     // firebase deploy  --only hosting:deeperks-merchant-dev
// }

// // PROD
export const WEB_CONFIG: any = {
    // base_url: "http://localhost:3000",
    base_url: "https://merchant.deeperks.com",
    marketplace_base_url: "https://marketplace.deeperks.com",
    // firebase deploy  --only hosting:deeperks-merchant-prod
}