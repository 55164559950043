import { Edit, Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  TablePagination,
  InputLabel,
  TextField,
  SelectChangeEvent,
  Theme,
  useTheme,
  MenuItem,
  Chip,
  FormControl,
  OutlinedInput,
  Select,
  Tooltip,
} from "@mui/material";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import AlertDialog from "../../Components/ConfirmDelete";
import DialogBox from "../../Components/Dialoag";
import Header from "../../Components/Header";
import Loader from "../../Components/Loader";
import Popup from "../../Components/Popup";
import SearchBar from "../../Components/SearchBar";
import { cardHeaderStyles, modalStyles } from "../../Components/Style";
import { getObjectById } from "../../utilities/Helper/objectHelpers";
import { setDateFormate } from "../../utilities/Helper/setDateFormate";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useDispatch, useSelector } from "react-redux";
import { storage } from "../../../services/firebase";
import {
  setHomeCards,
  getHomeCards,
  removeHomeCards,
  updateHomeCards,
} from "../../../store/reduxSlice/HomeCardsSlice";
import { getSession } from "../../utilities/Auth/AuthStates";
import { toast } from "react-toastify";
import HomeCardsList from "./HomeCardsList";
import { Flags } from "../../../data/Flags";
import { convertToRaw, convertFromRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { DB_COLLECTION } from "../../../constant/dbcollections";
import { getAllQuery } from "../../utilities/queries/getQuery";
import { CONSTANT } from "../../../constant";
import CollectionsIcon from "@mui/icons-material/Collections";
import ImageModal from "../../Components/ImagePreview";
import ImagesModal from "../../Components/ImagesModal";
import { getQRPayment } from "../../../store/reduxSlice/QRPaymentSlice";
import MUIDataTable from "mui-datatables";

export default function HomeCards() {
  const [isEditMode, setIsEditMode] = useState(false);
  const [disableButton, setDisableButton] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [retrieveData, setRetrieveData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [openModal, setOpenModal] = React.useState(false);
  const [values, setValues] = useState<any>([]);
  const [imagesLists, setimagesLists] = React.useState<any>([]);
  const [openImagesModal, setopenImagesModal] = React.useState<any>(false);
  const [image, setImage] = React.useState<any>([]);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [itemToDelete, setItemToDelete] = React.useState<any>([]);
  const [homeCardData, setHomeCardData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [inputList, setInputList] = useState("");
  const [descriptionItem, setDescriptionItem] = useState<any[]>([]);
  const [merchantArr, setmerchantArr] = useState([]);
  const [location, setLocation] = React.useState<string[]>([]);
  const data: any = useSelector((state: any) => state);
  const dispatch: any = useDispatch();
  const theme = useTheme();
  const rows = data?.homeCards?.homCardsData;
  const QRPaymentData = data?.QRPayment?.qrPaymentsData;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const options = {
    fixedSelectColumn: false,
      print: true as any,
      selectableRows: false as any,
      selectableRowsHeader: false as any,
      onRowClick: (rowData: any, rowMeta: any) => {
        // console.log("rowDatarowData", rowData)
        // rows.data.map((item: any) =>
        //     item?.id === rowData[4] ? setModalData(item) : ""
        // );
        // setModalData(rowData)
        // setModalOpen(true);
      },
  };
  
  const [convertedContent, setConvertedContent] = useState<any>(null);
  const [convertedContentTANDC, setConvertedContentTANDC] = useState<any>(null);

  const handleImagesModal = () => {
    setopenImagesModal(!openImagesModal);
  };

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [editorStateTANDC, setEditorStateTANDC] = useState(() =>
    EditorState.createEmpty()
  );

  const convertContentToHTML = () => {
    var rawJson = convertToRaw(editorState.getCurrentContent());
    console.log("state", rawJson);
    setConvertedContent(rawJson);
  };

  const convertContentToHTMLTANDC = () => {
    var rawJson = convertToRaw(editorStateTANDC.getCurrentContent());
    console.log("state", rawJson);
    setConvertedContentTANDC(rawJson);
  };

  const handleEditorChange = (state: any) => {
    setEditorState(state);
    convertContentToHTML();
    setDisableButton(false);
  };

  const handleEditorChangeTANDC = (state: any) => {
    setEditorStateTANDC(state);
    convertContentToHTMLTANDC();
    setDisableButton(false);
  };

  const getAllMerchant = async () => {
    let res = await getAllQuery(DB_COLLECTION.DP_MERCHANT);
    if (res.error === undefined) {
      setmerchantArr(res);
    }
  };

  useEffect(() => {
    dispatch(getHomeCards());
    setRetrieveData(data);
    dispatch(setHomeCards(rows));
    setRetrieveData(data);
    getAllMerchant();
    dispatch(getQRPayment());
  }, []);
  useEffect(() => {
    getSession();
    rows?.length > 0 && sort_data(rows);
  }, [rows]);

  const sort_data = (data: any) => {
    let sortedData: any = [...data];
    try {
      sortedData.sort((a: any, b: any) => {
        if (
          Number(a.homeCard_display_order) < Number(b.homeCard_display_order)
        ) {
          return -1;
        } else if (
          Number(a.homeCard_display_order) > Number(b.homeCard_display_order)
        ) {
          return 1;
        } else {
          return 0;
        }
      });
      convertIntoMUI(sortedData);
    } catch (e: any) {
      console.log("error in console  for sorting:", e);
    }
  };

  const convertIntoMUI = (data: any) => {
    let muiData: any=[];
    data.map((item: any) => muiData.push([
      item.homeCard_title,
      item.homeCard_display_order,
      item.homeCard_description,
      item.promoCodeClicks,
      item.homeCard_link,
      item.homeCard_Offerperiod,
      item.homeCard_code,
      item.homeCard_location,
      item.purchaseCount,
      item.coupan_description.blocks[0].text || "",
      item.terms_and_condition_description.blocks[0].text,
      <img
        alt="homeCard_logo"
        src={item.homeCard_logo}
        style={{ width: "90px" }}
      />,
      <img
        alt="homeCard_thumbnail"
        src={item.homeCard_thumbnail}
        style={{ width: "90px" }}
      />,
      <img
        alt="homeCard_banner"
        src={item.homeCard_banner}
        style={{ width: "20", height: "30px" }}
      />,
      <img
        alt="homeCard_detail_logo"
        src={item.homeCard_detail_logo}
        style={{ width: "20", height: "30px" }}
      />,
      <img
        alt="homeCard_paymentQR"
        src={item.homeCard_paymentQR}
        style={{ width: "90px" }}
      />,
      <Fragment>
        <Button
          sx={{ margin: "2px" }}
          variant="outlined"
          onClick={() => handleEditHomeCards(item)}
        >
          <Edit fontSize="small" />
        </Button>
        <Button
          sx={{ margin: "2px" }}
          variant="outlined"
          onClick={() => handleDelete(item)}
        >
          <Delete fontSize="small" />
        </Button>
        <Tooltip title={"Payment Receipt"}>
          <Button
            sx={{ margin: "2px" }}
            variant="outlined"
            onClick={() => getImagesList(item)}
          >
            <CollectionsIcon fontSize="small" />
          </Button>
          </Tooltip>
      </Fragment>
    ]))
    setHomeCardData(muiData);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleModalClose = () => {
    setOpenModal(false);
  };
  const handleClose = () => {
    setValues({
      ...values,
      homeCard_id: "",
      homeCard_title: "",
      // homeCard_description: "",
      homeCard_link: "",
      homeCard_Offerperiod: "",
      homeCard_code: "",
      homeCard_image: "",
      homeCard_thumbnail: "",
      homeCard_description: setDescriptionItem([]),
      location: [],
    });
    setConvertedContent("");
    setConvertedContentTANDC("");
    setEditorState(EditorState.createEmpty());
    setEditorStateTANDC(EditorState.createEmpty());
    setLocation([]);
    setImage({ homeCard_thumbnail: "", homeCard_image: "" });
    setOpen(false);
    setIsEditMode(false);
    setDisableButton(false);
  };
  const columns = [
    {
      name: "homeCard_title",
      label: "Title",
      options: {
        filter: true,
        sort: false,
        display: true
      }
    },
    {
      name: "homeCard_display_order",
      label: "Order",
      options: {
        filter: true,
        sort: false,
        display: false
      }
    },
    {
      name: "homeCard_description",
      label: "Description",
      options: {
        filter: true,
        sort: false,
        display: true
      }
    },
    {
      name: "promoCodeClicks",
      label: "Promo Clicks",
      options: {
        filter: true,
        sort: false,
        display: false
      }
    },
    {
      name: "homeCard_link",
      label: "Offer Link",
      options: {
        filter: true,
        sort: false,
        display: false
      }
    },
    {
      name: "homeCard_Offerperiod",
      label: "Offer Period",
      options: {
        filter: true,
        sort: false,
        display: false
      }
    },
    {
      name: "homeCard_code",
      label: "Code",
      options: {
        filter: true,
        sort: false,
        display: true
      }
    },
    {
      name: "homeCard_location",
      label: "Location",
      placeholder: "",
      options: {
        filter: true,
        sort: false,
        display: false
      }
    },
    {
      name: "purchaseCount",
      label: "Purchases",
      options: {
        filter: true,
        sort: false,
        display: true
      }
    },
    {
      name: "coupan_description",
      label: "Coupon Description",
      options: {
        filter: true,
        sort: false,
        display: true
      }
    },
    {
      name: "terms_and_condition_description",
      label: "Terms and Condition Description",
      options: {
        filter: true,
        sort: false,
        display: false
      }
    },
    {
      name: "homeCard_logo",
      label: "Logo",
      options: {
        filter: true,
        sort: false,
        display: false
      }
    },
    {
      name: "homeCard_thumbnail",
      label: "Thumbnail",
      options: {
        filter: true,
        sort: false,
        display: false
      }
    },
    {
      name: "homeCard_banner",
      label: "Banner",
      options: {
        filter: true,
        sort: false,
        display: false
      }
    },
    {
      name: "homeCard_logo",
      label: "Detail Page Logo * (480x320)",
      options: {
        filter: true,
        sort: false,
        display: false
      }
    },
    {
      name: "homeCard_paymentQR",
      label: "Payment QR",
      options: {
        filter: true,
        sort: false,
        display: false
      }
    },
    {
      name: "action",
      label: "Action",
      placeholder: "",
      minWidth: 170,
    },
  ];
  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
    setLoading(false);
    setDisableButton(false);
  };
  const inputFieldValues = [
    {
      id: "homeCard_title",
      name: "homeCard_title",
      label: "Title *",
      placeholder: "Enter title",
      type: "text",
    },
    // {
    //   id: "homeCard_description ",
    //   name: "homeCard_description",
    //   label: "Description *",
    //   placeholder: "Enter Description",
    //   type: "text",
    // },
    {
      id: "homeCard_link",
      label: "Offer Link",
      minWidth: 170,
    },
    {
      id: "homeCard_Offerperiod",
      name: "homeCard_Offerperiod",
      label: "Period",
      placeholder: "Enter Offer Period",
      type: "text",
    },
    {
      id: "homeCard_code",
      name: "homeCard_code",
      label: "Coupon Code",
      placeholder: "Enter Code",
      type: "text",
    },
    {
      id: "homeCard_logo",
      name: "homeCard_logo",
      label: "Home Card Logo Image * (415 * 50)",
      placeholder: "",
      type: "file",
    },
    {
      id: "homeCard_banner",
      name: "homeCard_banner",
      label: "Home Card Banner Image * (1020 * 380)",
      placeholder: "",
      type: "file",
    },
    {
      id: "homeCard_thumbnail",
      name: "homeCard_thumbnail",
      label: "Thumbnail * (415 * 230)",
      placeholder: "",
      type: "file",
    },
    {
      id: "homeCard_detail_logo",
      name: "homeCard_detail_logo",
      label: "Detail Page Logo * (480x320)",
      placeholder: "",
      type: "file",
    },
    {
      id: "homeCard_display_order",
      name: "homeCard_display_order",
      label: "Enter Display order",
      placeholder: "",
      type: "number",
    },
    {
      id: "homeCard_paymentQR",
      name: "homeCard_paymentQR",
      label: "QR Image (480x480)",
      placeholder: "",
      type: "file",
    },
    {
      id: "coupan_description",
      name: "coupan_description",
      label: "Coupon Description",
      placeholder: "",
      type: "text",
      // required: true,
    },
    {
      id: "terms_and_condition_description",
      name: "terms_and_condition_description",
      label: "Terms and Condition Description",
      placeholder: "",
      type: "text",
      // required: true,
    },
  ];
  const handleMultiSelect = (event: SelectChangeEvent<typeof location>) => {
    const {
      target: { value },
    } = event;
    let array = typeof value === "string" ? value.split(",") : value;
    let editValues = {
      ...values,
      location: array,
    };
    setValues(editValues);
    setLocation(typeof value === "string" ? value.split(",") : value);
  };
  function getStyles(name: string, location: readonly string[], theme: Theme) {
    return {
      fontWeight: location.indexOf(name) === -1 ? "normal" : "bold",
    };
  }
  const handleImage = (event: any) => {
    const { name, value, files } = event.target;
    if (files[0] != null) {
      setImage({
        ...image,
        [name]: files,
      });
      setDisableButton(false);
    } else {
      toast.error(`Select valid image extension!`, {
        className: "toast-message",
      });
    }
  };
  const getContent = () => (
    <div>
      <Box sx={modalStyles.inputFields}>
        {window.localStorage.getItem(CONSTANT.DEEPERKS_SESSION_ADMIN) ===
          "true" && ( // This dropdown only for admin
          <div style={{ display: "flex" }}>
            <FormControl fullWidth={true}>
              <InputLabel id="demo-simple-select-label">
                Select Merchant *
              </InputLabel>
              <Select
                required
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="merchant_id"
                label="Merchant*"
                defaultValue={values["merchant_id"]}
                variant="outlined"
                onBlur={handleChange}
                onChange={handleChange}
              >
                {merchantArr && merchantArr.length > 0 ? (
                  merchantArr.map((marchant: any) => {
                    return (
                      <MenuItem
                        // key={eachCategory.cat_id}
                        // value={`${eachCategory.cat_id}`}
                        key={marchant.id}
                        value={marchant.id}
                      >
                        {marchant.firstName}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem> </MenuItem>
                )}
              </Select>
            </FormControl>
          </div>
        )}

        {inputFieldValues.map((eachField: any) =>
          eachField.id === "coupan_description" ||
          eachField.id === "terms_and_condition_description" ? (
            <>
              <InputLabel
                id="demo-simple-select-label"
                style={{ marginBottom: "10px" }}
              >
                {eachField.label}
              </InputLabel>
              <Editor
                editorState={
                  eachField.id === "coupan_description"
                    ? editorState
                    : editorStateTANDC
                }
                onEditorStateChange={
                  eachField.id === "coupan_description"
                    ? handleEditorChange
                    : handleEditorChangeTANDC
                }
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
              />
            </>
          ) : (
            <>
              <InputLabel style={{ margin: "5px 5px" }}>
                {eachField.label}
              </InputLabel>
              <TextField
                // fullWidth={eachField.id === "homeCard_description" ? false : true}
                defaultValue={
                  eachField.type === "file" ? "" : values[eachField.id]
                }
                placeholder={eachField.placeholder}
                name={eachField.id}
                type={eachField.type}
                // multiline={eachField.id === "homeCard_description" ? true : false}
                onChange={(event: any) => {
                  eachField.type === "file"
                    ? handleImage(event)
                    : handleChange(event);
                }}
              />
            </>
          )
        )}
        <div>
          {/* multi select  */}
          <FormControl fullWidth={true}>
            <InputLabel id="demo-simple-select-label">Region</InputLabel>
            <Select
              fullWidth={true}
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={location} //
              onChange={handleMultiSelect}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value: any) => (
                    <Chip key={value} label={value} />
                    // <div
                    //   style={{
                    //     backgroundColor: "#E8E8E8	",
                    //     borderRadius: 4,
                    //     display: "flex",
                    //     justifyContent: "center",
                    //     alignItems: "center",
                    //     padding: 2,
                    //   }}
                    // >
                    //   <img src={value.split(" ")[0]} alt="flags" />
                    //   <span style={{ padding: 0, marginLeft: 2 }}>
                    //     {" "}
                    //     {" " +
                    //       value.split(" ")[1] +
                    //       " " +
                    //       (value.split(" ")[2] ? value.split(" ")[2] : "")}
                    //   </span>
                    // </div>
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {Flags.map((item: any) => (
                <MenuItem
                  key={item.name}
                  // value={item.mini + " " + item.name}
                  value={item.name}
                  style={getStyles(item.name, location, theme)}
                >
                  {
                    <div style={{}}>
                      {/* <img src={item?.mini} /> */}
                      {item.name}
                    </div>
                  }
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Box>
      <div>
        <InputLabel style={{ margin: "5px 5px" }}>{"Description *"}</InputLabel>
        <div
          style={{ display: "flex", flexDirection: "row", marginBottom: 20 }}
        >
          <TextField
            style={{ width: "90%" }}
            id={"homeCard_description "}
            name="homeCard_description"
            placeholder="Enter Description"
            value={inputList}
            onChange={handleDescription}
            type="text"
            multiline
          />
          <Button
            // variant="contained"
            style={{
              width: "10%",
              border: "1px solid gray",
              backgroundColor: "#E8E8E8",
              justifyContent: "center",
              textAlign: "center",
              // borderRadius: 30,
              marginLeft: 3,
            }}
            onClick={handleDescriptionItemList}
          >
            Add +
          </Button>
        </div>
        <ul style={{ listStyleType: "none" }}>
          {/* <li>{inputList}</li> */}
          {descriptionItem.map((list: any, index: any) => {
            return (
              <HomeCardsList
                key={index}
                list={list}
                id={index}
                onSelect={deleteItems}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
  const deleteItems = (id: any) => {
    setDescriptionItem((items: any) => {
      return items.filter((listItem: any, index: any) => {
        return index !== id;
      });
    });
  };
  const handleDescriptionItemList = () => {
    setDescriptionItem((items: any) => {
      return [...(items as any), inputList];
    });
    setInputList("");
  };
  const handleDescription = (event: any) => {
    setInputList(event.target.value);
  };

  async function uploadImageTask(uploadTask: any) {
    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot: any) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        (error: any) => {
          alert(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        }
      );
    });
  }
  const handleFormSubmit = async (file: any) => {
    if (
      image &&
      values.homeCard_title &&
      values.homeCard_title !== undefined &&
      values.homeCard_title !== "undefined" &&
      // values.homeCard_link &&
      // values.homeCard_link !== undefined &&
      // values.homeCard_link !== "undefined" &&
      descriptionItem.length !== 0 &&
      values.homeCard_display_order &&
      values.homeCard_display_order !== undefined &&
      convertedContent &&
      convertedContent != undefined &&
      convertedContent != "undefined" &&
      convertedContentTANDC &&
      convertedContentTANDC != undefined &&
      convertedContentTANDC != "undefined"
      // values.homeCard_description !== "undefined" &&
      // values.homeCard_Offerperiod &&
      // values.homeCard_Offerperiod !== undefined &&
      // values.homeCard_Offerperiod !== "undefined"
      // &&
      // values.homeCard_code &&
      // values.homeCard_code !== undefined &&
      // values.homeCard_code !== "undefined"
    ) {
      setLoading(true);
      let allHomeCardData: any = {};
      if (image) {
        for (const key in image) {
          const element = image[key];
          if (key) {
            let imageName = Math.round(Math.random() * 1000000000);
            const storageRef = ref(storage, `/HomeCards/${key}/${imageName}`);
            const uploadTask = uploadBytesResumable(storageRef, element[0]);
            const getThumbnailUrl = await uploadImageTask(uploadTask);
            values[key] = getThumbnailUrl;
            allHomeCardData = {
              ...values,
            };
          }
        }
        // homeCard_description: descriptionItem,
        if (allHomeCardData) {
          allHomeCardData = {
            ...values,
            homeCard_description: descriptionItem,
            terms_and_condition_description: convertedContentTANDC,
            coupan_description: convertedContent,
          };
          dispatch(setHomeCards(allHomeCardData));
          handleClose();
          dispatch(getHomeCards());
          setLoading(false);
        }
      }
    } else {
      // setLoading(true);
      setDisableButton(true);
    }
  };
  const dialogContent = ///
    "Sorry you are not able to delete this.Please delete subcatefory brand and deal first";

  const handleFormSubmitAfterEdit = async (file: any) => {
    console.log("inside update");
    if (
      image
      // && values.homeCard_link.length > 0
    ) {
      let allHomeCardData = {
        ...values,
      };
      if (image) {
        setLoading(true);
        if (
          image.hasOwnProperty("homeCard_thumbnail") &&
          image.hasOwnProperty("homeCard_logo") &&
          image.hasOwnProperty("homeCard_banner") &&
          image.hasOwnProperty("homeCard_detail_logo") &&
          image.hasOwnProperty("homeCard_paymentQR") &&
          typeof image.homeCard_banner === "object" &&
          typeof image.homeCard_logo === "object" &&
          typeof image.homeCard_thumbnail === "object" &&
          typeof image.homeCard_detail_logo === "object"
        ) {
          for (const key in image) {
            const element = image[key];
            if (key) {
              let imageName = Math.round(Math.random() * 1000000000);
              const storageRef = ref(storage, `/HomeCards/${key}/${imageName}`);
              const uploadTask = uploadBytesResumable(storageRef, element[0]);
              const getThumbnailUrl = await uploadImageTask(uploadTask);
              values[key] = getThumbnailUrl;
              allHomeCardData = {
                ...values,
              };
            }
          }
        } else if (
          image.hasOwnProperty("homeCard_logo") &&
          typeof image.homeCard_logo === "object"
        ) {
          let imageName = Math.round(Math.random() * 1000000000);
          const storageRef = ref(
            storage,
            `/HomeCards/homeCard_logo/${imageName}`
          );
          const uploadTask = uploadBytesResumable(
            storageRef,
            image.homeCard_logo[0]
          );
          const getSliderUrl = await uploadImageTask(uploadTask);
          values["homeCard_logo"] = getSliderUrl;
          allHomeCardData = {
            ...values,
          };
        } else if (
          image.hasOwnProperty("homeCard_thumbnail") &&
          typeof image.homeCard_thumbnail === "object"
        ) {
          let imageName = Math.round(Math.random() * 1000000000);
          const storageRef = ref(
            storage,
            `/HomeCards/homeCard_thumbnail/${imageName}`
          );
          const uploadTask = uploadBytesResumable(
            storageRef,
            image.homeCard_thumbnail[0]
          );
          const getThumbnailUrl = await uploadImageTask(uploadTask);
          values["homeCard_thumbnail"] = getThumbnailUrl;
          allHomeCardData = {
            ...values,
          };
        } else if (
          image.hasOwnProperty("homeCard_banner") &&
          typeof image.homeCard_banner === "object"
        ) {
          let imageName = Math.round(Math.random() * 1000000000);
          const storageRef = ref(
            storage,
            `/HomeCards/homeCard_banner/${imageName}`
          );
          const uploadTask = uploadBytesResumable(
            storageRef,
            image.homeCard_banner[0]
          );
          const getThumbnailUrl = await uploadImageTask(uploadTask);
          values["homeCard_banner"] = getThumbnailUrl;
          allHomeCardData = {
            ...values,
          };
        } else if (
          image.hasOwnProperty("homeCard_detail_logo") &&
          typeof image.homeCard_detail_logo === "object"
        ) {
          let imageName = Math.round(Math.random() * 1000000000);
          const storageRef = ref(
            storage,
            `/HomeCards/homeCard_detail_logo/${imageName}`
          );
          const uploadTask = uploadBytesResumable(
            storageRef,
            image.homeCard_detail_logo[0]
          );
          const getThumbnailUrl = await uploadImageTask(uploadTask);
          values["homeCard_detail_logo"] = getThumbnailUrl;
          allHomeCardData = {
            ...values,
          };
        } else if (
          image.hasOwnProperty("homeCard_paymentQR") &&
          typeof image.homeCard_paymentQR === "object"
        ) {
          let imageName = Math.round(Math.random() * 1000000000);
          const storageRef = ref(
            storage,
            `/HomeCards/homeCard_paymentQR/${imageName}`
          );
          const uploadTask = uploadBytesResumable(
            storageRef,
            image.homeCard_paymentQR[0]
          );
          const getThumbnailUrl = await uploadImageTask(uploadTask);
          values["homeCard_paymentQR"] = getThumbnailUrl;
          allHomeCardData = {
            ...values,
          };
        }

        if (allHomeCardData) {
          allHomeCardData = {
            ...values,
            homeCard_description: descriptionItem,
            terms_and_condition_description: convertedContentTANDC,
            coupan_description: convertedContent,
          };
          await dispatch(updateHomeCards(allHomeCardData));
          handleClose();
          await dispatch(getHomeCards());
          setLoading(false);
          setIsEditMode(false);
        }
      }
    } else {
      setDisableButton(true);
      setIsEditMode(false);
    }
  };

  const handleEditHomeCards = (data: any) => {
    setIsEditMode(true);
    setValues({
      ...values,
      homeCard_id: data?.homeCard_id || "",
      homeCard_title: data?.homeCard_title || "",
      homeCard_logo: data?.homeCard_logo || "",
      homeCard_banner: data?.homeCard_banner || "",
      homeCard_thumbnail: data?.homeCard_thumbnail || "",
      homeCard_detail_logo: data?.homeCard_detail_logo || "",
      homeCard_link: data?.homeCard_link || "",
      homeCard_Offerperiod: data?.homeCard_Offerperiod || "",
      homeCard_code: data?.homeCard_code || "",
      homeCard_location: data?.homeCard_location || "",
      homeCard_display_order: data?.homeCard_display_order || "",
      coupan_description: data?.coupan_description || "",
      terms_and_condition_description:
        data?.terms_and_condition_description || "",
    });
    if (data.coupan_description) {
      setEditorState(
        EditorState.createWithContent(convertFromRaw(data.coupan_description))
      );
      setConvertedContent(data.coupan_description);
    }
    if (data.terms_and_condition_description) {
      setEditorStateTANDC(
        EditorState.createWithContent(
          convertFromRaw(data.terms_and_condition_description)
        )
      );
      setConvertedContentTANDC(data.terms_and_condition_description);
    }
    setLocation(data?.homeCard_location ? data?.homeCard_location : []);
    setImage({
      homeCard_logo: data?.homeCard_logo,
      homeCard_banner: data?.homeCard_banner,
      homeCard_thumbnail: data?.homeCard_thumbnail,
      homeCard_detail_logo: data?.homeCard_detail_logo,
      homeCard_paymentQR: data?.homeCard_paymentQR,
    });
    setDescriptionItem(data?.homeCard_description);
    handleOpen();
    console.log("edit data function end:", data, "=====", values);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
    setItemToDelete([]);
  };
  const handleDelete = (item: any) => {
    setOpenAlert(true);
    setItemToDelete([item]);
  };
  const handleConfirmDelete = async () => {
    setLoading(true);
    await dispatch(removeHomeCards(itemToDelete[0]));
    await dispatch(getHomeCards());
    setItemToDelete([]);
    setLoading(false);
    handleCloseAlert();
  };

  const handleChangeSearch = (e: any) => {
    let filteredData: any = [];
    e.preventDefault();
    if (e.target.value?.length > 0) {
      filteredData = rows.filter((row: any) => {
        return (
          row?.homeCard_title
            ?.toLowerCase()
            .match(e.target.value?.toLowerCase()) ||
          row?.homeCard_description
            ?.toLowerCase()
            ?.match(e.target.value.toLowerCase()) ||
          row?.homeCard_link
            ?.toLowerCase()
            .match(e.target.value?.toLowerCase()) ||
          row?.homeCard_Offerperiod
            ?.toLowerCase()
            .match(e.target.value?.toLowerCase()) ||
          row?.homeCard_code?.toLowerCase().match(e.target.value?.toLowerCase())
        );
      });
      setSearchInput(e.target.value);
      setHomeCardData(filteredData);
    } else {
      setHomeCardData(rows);
    }
  };

  const getImagesList = (homeCardData: any) => {
    let imagesForModal: any = [];
    QRPaymentData.map((item: any) => {
      if (
        homeCardData.id === item.homeCard_id &&
        item.recipt_url !== undefined
      ) {
        imagesForModal.push(item.recipt_url);
      }
    });
    setimagesLists(imagesForModal);
    handleImagesModal();
  };

  return (
    <>
      <Header>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ fontSize: "2em" }}>Home Cards</div>
        </div>
      </Header>
      <Box sx={cardHeaderStyles.wrapper}>
        {/* Search Bar */}
        {/* <SearchBar
          onChange={handleChangeSearch}
          value={searchInput}
          placeholder="Search by Name or Description"
          searchBarWidth="720px"
        /> */}
        <Box>
          <div>
            {/* <Button variant="contained" onClick={handleOpen}>
              <AddIcon />
              Add Home Cards
            </Button> */}
            <IconButton>
              <RefreshIcon />
            </IconButton>
            <div>
              {/* add/edit Modal */}
              <Popup
                open={open}
                onClose={handleClose}
                title={isEditMode ? "Edit Home Cards" : "Add Home Cards"}
                buttonText={
                  isEditMode ? "Update Home Cards" : "Submit Home Cards"
                }
                content={getContent()}
                values={values}
                handleFormSubmit={() => handleFormSubmit(image)}
                disabled={disableButton}
                isLoading={isLoading}
                isEditMode={isEditMode}
                handleFormSubmitAfterEdit={() =>
                  handleFormSubmitAfterEdit(image)
                }
              />
            </div>
          </div>
        </Box>
      </Box>

      {/*Table Start  */}

      <div
        style={{ marginLeft: "4%",}}
      >
        {/* <Grid container>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 700 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        align={"center"}
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: "bold",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {homeCardData && homeCardData?.length > 0 ? (
                    homeCardData
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.map((row: any) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            // key={row.code}
                          >
                            <TableCell key={"homeCard_title"} align={"center"}>
                              {row.homeCard_title}
                            </TableCell>
                            <TableCell
                              key={"homeCard_display_order"}
                              align={"center"}
                            >
                              {row.homeCard_display_order}
                            </TableCell>
                            <TableCell
                              width={500}
                              key={"homeCard_description"}
                              // align={"center"}
                            >
                              <ul>
                                {row?.homeCard_description.map((item: any) => (
                                  <li>{item}</li>
                                ))}
                              </ul>
                            </TableCell>
                            <TableCell key={"homeCard_title"} align={"center"}>
                              {row.promoCodeClicks ? row.promoCodeClicks : "0"}
                            </TableCell>
                            <TableCell
                              key={"homeCard_Offerlink"}
                              align={"center"}
                            >
                              {row.homeCard_link}
                            </TableCell>
                            <TableCell
                              key={"homeCard_Offerperiod"}
                              align={"center"}
                            >
                              {row.homeCard_Offerperiod}
                            </TableCell>
                            <TableCell key={"homeCard_code"} align={"center"}>
                              {row.homeCard_code}
                            </TableCell>
                            <TableCell style={{ width: 180 }} align={"left"}>
                              <ul>
                                {row?.location && row?.location.length > 0
                                  ? row?.location.map((item: any) => {
                                      return <li>{item.country || item}</li>;
                                    })
                                  : "No Location Available"}
                              </ul>
                            </TableCell>
                            <TableCell
                              style={{ padding: "5px" }}
                              align={"center"}
                            >
                              <h3>{row?.purchaseCount || 0}</h3>
                            </TableCell>
                            <TableCell key={"homeCard_code"} align={"center"}>
                              {row.coupan_description
                                ? row.coupan_description.blocks[0].text
                                : ""}
                            </TableCell>
                            <TableCell key={"homeCard_code"} align={"center"}>
                              {
                                row.terms_and_condition_description.blocks[0]
                                  .text
                              }
                            </TableCell>

                            <TableCell
                              align={"center"}
                              key={"homeCard_logo"}
                              style={{ padding: "5px" }}
                            >
                              <img
                                alt="homeCard_logo"
                                src={row.homeCard_logo}
                                style={{ width: "90px" }}
                              ></img>
                            </TableCell>

                            <TableCell
                              align={"center"}
                              key={"homeCard_thumbnail"}
                              style={{ padding: "5px" }}
                            >
                              <img
                                alt="homeCard_thumbnail"
                                src={row.homeCard_thumbnail}
                                style={{ width: "90px" }}
                              ></img>
                            </TableCell>
                            <TableCell
                              align={"center"}
                              key={"homeCard_banner"}
                              style={{ padding: "5px" }}
                            >
                              <img
                                alt="homeCard_banner"
                                src={row.homeCard_banner}
                                style={{ width: "20", height: "30px" }}
                              ></img>
                            </TableCell>
                            <TableCell
                              align={"center"}
                              key={"homeCard_detail_logo"}
                              style={{ padding: "5px" }}
                            >
                              <img
                                alt="homeCard_detail_logo"
                                src={row.homeCard_detail_logo}
                                style={{ width: "20", height: "30px" }}
                              ></img>
                            </TableCell>
                            <TableCell
                              align={"center"}
                              key={"homeCard_detail_logo"}
                              style={{ padding: "5px" }}
                            >
                              <img
                                alt="homeCard_paymentQR"
                                src={row.homeCard_paymentQR}
                                style={{ width: "90px" }}
                              ></img>
                            </TableCell>
                            <TableCell align={"center"}>
                              <Button
                                sx={{ margin: "2px" }}
                                variant="outlined"
                                onClick={() => handleEditHomeCards(row)}
                              >
                                <Edit fontSize="small" />
                              </Button>
                              <Button
                                sx={{ margin: "2px" }}
                                variant="outlined"
                                onClick={() => handleDelete(row)}
                              >
                                <Delete fontSize="small" />
                              </Button>
                              <Tooltip title={"Payment Receipt"}>
                                <Button
                                  sx={{ margin: "2px" }}
                                  variant="outlined"
                                  onClick={() => getImagesList(row)}
                                >
                                  <CollectionsIcon fontSize="small" />
                                </Button>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <Typography
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: 15,
                        right: "40%",
                        // border: "1px solid black"
                      }}
                    >
                      No Home Cards Found
                    </Typography>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid> */}
        <MUIDataTable
          title={"Home Card List"}
          data={homeCardData}
          columns={columns}
          options={options}
        />
        <AlertDialog
          openAlert={openAlert}
          handleCloseAlert={handleCloseAlert}
          handleConfirmDelete={handleConfirmDelete}
          isLoading={isLoading}
        />
        <Loader isLoading={isLoading} />
        <DialogBox
          openModal={openModal}
          handleModalClose={handleModalClose}
          dialogContent={dialogContent}
        ></DialogBox>
        <ImagesModal
          images={imagesLists}
          isOpen={openImagesModal}
          onClose={handleImagesModal}
        />
      </div>
    </>
  );
}
